/* @import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300;400;500;600;700;800&display=swap'); */

/* @import url(//db.onlinewebfonts.com/c/77f92dc516bc072923addac1da40ed0f?family=Lota+Grotesque); */
@font-face {
  font-family: 'Lota Grotesque';
  src: url(/fonts/LotaGrotesque.woff2) format('woff2'),
    url('/fonts/LotaGrotesque.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lota Grotesque';
  src: url(/fonts/LotaGrotesqueBold.woff2) format('woff2'),
    url('/fonts/LotaGrotesqueBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'MTNBrighterSans-ExtraBold';
  src: url('/fonts/MTNBrighterSans-ExtraBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MTNBrighterSans-Regular';
  src: url('/fonts/MTNBrighterSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Lota Grotesque', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input[type='radio']:checked + span {
  color: blue;
}
input[type='checkbox']:checked + span {
  color: blue;
}
input[type='checkbox'] + span {
  color: blue;
}

.paystack-button {
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.1rem;
  background-color: #0f61d6;
  font-weight: bold;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  margin-top: 40px;
}

.icon-outline {
  stroke: #ec3242;
  stroke-width: 30px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  color: #dac5c6;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.intercom-launcher {
  animation: pulse 1s infinite;
}

.css-1ofkfip[aria-checked='true'],
.css-1ofkfip[data-checked] {
  box-shadow: 0px 0px 0px 1px rgb(253, 203, 9) !important;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}

.chakra-radio.brand {
  width: 100%;
}
